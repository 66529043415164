@media only screen and (max-width: 360px) {
	.ant-card.sc-bwzfXH.dYTUcd {
		height: 530px;
		margin-top: -15px;
	}
	#rc-imageselect,
	.g-recaptcha {
		transform: scale(0.77);
		-webkit-transform: scale(0.77);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}
.logo {
	display: block !important;
	margin: 0rem auto 0 auto;
	width: 70% !important;
}

.login-card {
	height: 650px;
	top: 10%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	background-color: #eff2f7;
}
.verifycode-card {
	height: 630px;
	top: 10%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	background-color: #eff2f7;
}
.forget-pass-card {
	height: 500px;
	top: 10%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	background-color: #eff2f7;
}

.signup-card {
	height: 675px;
	top: 5%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	background-color: #eff2f7;
}

.fill-height {
	height: 100vh;
}

.login-message {
	text-align: center;
	// margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
	color: #173f5f;
}

.error-message {
	text-align: center;
	// margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
	color: #cf4500;
}

.success-message {
	text-align: center;
	margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
	color: #22bb33;
}

.txt-field {
	width: 70% !important;
	border-radius: 10px;
}

.nv-btn {
	width: 70% !important;
	border-radius: 10px;
	padding: 0 !important;
}

.div-txt-forget-password {
	font-size: 1rem;
	width: 70% !important;
	margin: 0 auto;
	text-align: right;
}
.div-txt-contact-us{
	margin: 0 auto;
	text-align: center;
	
}

.login-with-label{
	margin: 0 auto;
	width: 70% !important;
	display: flex;
	justify-content: space-around;
	font-size: 1rem;
	font-weight: bold;
}

// xero and qucikbooks login button style
.sso-login-button{
	border: none;
	width: 43%;
	cursor: pointer;
}
#xero{
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;
}
#quickbooks{
	background-color: #0077C5;
	color: white;
	font-size: 16px;
	font-weight: 600;
	padding-top: 6px;
	padding-bottom: 6px;
}
.sso-login-button-intuit{
	border: none;
	padding: 0;
	cursor: pointer;
}
