@import '~antd/dist/antd.css';
html,
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.ant-layout {
    min-width: 360px !important;
    overflow: auto;
}

.log-modal .ant-list-item {
    justify-content: start !important;
    width: 100%;
    line-height: 1;
}

.log-modal .ant-modal-header {
    border-bottom: 1px solid #3330;
}

.log-modal .ant-modal-close {
    background: #fff;
}

.log-modal .ant-modal-title .ant-divider {
    font-weight: bold;
    margin: 0 0;
}

.log-modal .ant-list-item:hover {
    /* background-color: #009fff0a; */
    background-image: linear-gradient(90deg, #106acb 0%, #0097f8 100%) !important;
}

.code-bash{
    border: 1px solid #3331;
    border-radius: 5px;
    background: #64b1ea12;
    padding: 5px 5px 5px 10px;
    align-items: center;
}

.float-btn{
    position: fixed;
    bottom: 30px;
    right: 50px;
    background: #1ddd29;
    box-shadow: 2px 2px 14px #3333;
    border: navajowhite;
    font-weight: bold;
}

.spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
.spinner {
    background: rgba(255,255,255,0.08);
}
.ant-form-item{
   margin-bottom: 10px !important; 
}