.main-layout-style{
    height: 100vh;
    // background-image: linear-gradient(0deg, #fed9fa, #b4b9fc) !important;
    // background-color: #D3D3D3 !important;
    background-color: #F7F5F2 !important;
}

.child-layout-style{
    // background-image: linear-gradient(0deg, #e5f5fd , #dce8ff) !important;
    background: transparent !important;
}
.common-style{
    border-radius: 5px !important;
    box-shadow:rgba(0, 0, 0, 0.2) 0px 7.5px 15px 0.1px;
}


  