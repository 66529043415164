.ant-table-tbody > tr.ant-table-row:hover > td {
	// background: #093849 !important;
	background: #0097f8 !important;
	color: #ffff;
}

.ant-form-large .ant-form-item-label > label {
	font-size: 0.8rem;
}

.fill-width {
	width: 100% !important;
}

.margin-auto {
	margin: auto !important;
}

.ant-btn.btn-action {
	margin: auto 0.5rem !important;
}

.with-prompt {
	.message {
		div.ant-card-body {
			background: #62d493 !important;
			font-size: 2rem !important;
			color: #fff !important;
			width: 100%;
		}
	}

	.error {
		div.ant-card-body {
			background: salmon !important;
			color: #fff !important;
			font-size: 1rem !important;
			width: 100%;
		}
	}
}

.btn-200 {
	width: 200px;
	margin: 0.5rem;
}

.padded {
	padding:0.5rem 2rem 2rem 2rem;
}

.padded-horizontal {
	padding: 0 2rem;
}

.padded-vertical {
	padding: 2rem 0;
}

.hidden {
	display: none !important;
}

.container-scroll {
	overflow-y: scroll !important;
	overflow-x: hidden !important;
	height: 80vh !important;
}

.wrapper {
	margin-top: 2rem;
}

.btn-center {
	display: block !important;
	margin: auto auto 1rem auto;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #093849;
	border-radius: 10px;
}

.xinvform .ant-col.ant-col-22{
	margin-top: 0px !important;
}
.xinvform .title {
    font-size: 1.2rem;
    padding: 0.5rem 2rem 1rem 2rem !important;
	margin-top: 0;
}
