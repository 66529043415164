.right-arrow {
  font-size: 1.5rem;
  margin: auto 1rem;
}

.title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 2rem;
  color: black;
}

.message {
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0;
}

.btn {
  margin: 6px 6px 24px 6px;
  border-radius: 5px !important;
}

.ant-col ant-form-item-label {
  padding: 0 !important;
  margin: 0 !important;
}

.wf-row {
  padding: 14px;
}

.select-app {
  .ant-select-selector {
    border-radius: 1rem !important;
    padding: auto !important;
    height: 3rem !important;
  }
}