
@media only screen and (max-width: 360px) {
	.ant-card.sc-bwzfXH.dYTUcd {
		height: 530px;
		margin-top: -15px;
	}
	#rc-imageselect,
	.g-recaptcha {
		transform: scale(0.77);
		-webkit-transform: scale(0.77);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}
.logo {
	display: block !important;
	margin: 0rem auto 0 auto;
	width: 70% !important;
}
.txt-field {
	width: 70% !important;
	border-radius: 10px;
}

.change-pass-card {
	height: 500px;
	top: 5%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	background-color: #eff2f7;
}