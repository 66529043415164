@media only screen and (max-width: 360px) {
	.ant-card.sc-bwzfXH.dYTUcd {
		height: 530px;
		margin-top: -15px;
	}
	#rc-imageselect,
	.g-recaptcha {
		transform: scale(0.77);
		-webkit-transform: scale(0.77);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
}

.forget-pass-card {
	height: 500px;
	top: 10%;
	box-shadow: 0 0 20px 20px;
	border-radius: 2px;
	// background-color: #eff2f7;
}

.register-card {
	height: 600px;
	top: 5%;
	border-radius: 2px;
}

.login-message {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	color: #173f5f;
}

.error-message {
	text-align: center;
	// margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
	color: #cf4500;
}

.success-message {
	text-align: center;
	margin-top: 2rem;
	font-size: 16px;
	font-weight: bold;
	color: #22bb33;
}

.txt-field {
	width: 70% !important;
	// border-radius: 10px !important;
}

.nv-btn {
	width: 70% !important;
	border-radius: 10px;
	padding: 0 !important;
}

.div-txt-forget-password {
	font-size: 1rem;
	width: 70% !important;
	margin: 0 auto;
	text-align: right;
}
.div-txt-contact-us{
	margin: 0 auto;
	text-align: center;
	
}

.login-with-label{
	margin: 0 auto;
	width: 70% !important;
	display: flex;
	justify-content: space-around;
	font-size: 1rem;
	font-weight: bold;
}

// xero and qucikbooks login button style
.sso-login-button{
	border: none;
	width: 43%;
	cursor: pointer;
}
#register-layout{
	background: transparent !important;
}

