.container {
    // max-width: 1320px;
    margin: 0 auto;
    padding: 0 2rem;
    // height: 100%;
}

.title-text {
    font-size: 46px;
    font-weight: normal;
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1%;
    padding: 1% 0;
}

.card {
    padding: 80px;
    width: 95%;
    background: #ffffff1a;
    border: 2px solid #093849;
    backdrop-filter: blur(10px);
    border-radius: 25px;
}

.card_title {
    font-weight: normal;
    font-size: 36px;
    margin-bottom: 20px;
    color: #093849
}

.pricing {
    font-weight: normal;
    font-size: 96px;
    color: #093849
}

.pricing .small {
    font-size: 16px;
}

hr {
    margin-top: 10px;
}

.features {
    margin: 40px 0;
    list-style-position: inside;
}

.features li {
    padding-bottom: 10px;
}

.cta_btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    background: #093849;
    border-radius: 29px;
    padding: 20px 0;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
    transition: background .3s ease;
}

.cta_btn_disabled {
    width: 100%;
    display: inline-block;
    text-align: center;
    background: #7a7a7a;
    border-radius: 29px;
    padding: 20px 0;
    color: white;
    text-decoration: none;
    letter-spacing: 2px;
    transition: background .3s ease;
}

// .cta_btn:hover {
//     background: #093849;
//     color: white;
//     cursor: pointer;
// }

.link {
    position: fixed;
    background-color: #D12322;
    padding: 23px 40px;
    right: -99px;
    border-radius: 5px;
    top: 50%;
    transform: translateY(-50%);
    transform: rotate(-90deg);
    font-size: 18px;
    font-weight: 500;
    color: #FFF;
    text-decoration: none;
    text-transform: capitalize;
    transition: all .1s ease-in-out;
}

.link i {
    padding-left: 7px;
}

.link:hover {
    text-decoration: underline;
    background-color: black;
}

@media only screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: 1fr 1fr;
        gap: 2%;
    }
}

@media only screen and (max-width: 425px) {
    .grid {
        grid-template-columns: 1fr;
        gap: 2%;
        padding-bottom: 25%;
    }

    .container {
        padding: 0 1rem;
    }

    // body {
    //     font-size: 14px;
    // }
    .card {
        padding: 30px;
    }

    .card_title {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .pricing {
        font-size: 52px;
    }

    hr {
        margin-top: 50px;
    }

    a.cta_btn {
        font-size: 11px;
    }
}


.subscription-wrapper {
    width: 100%;
    // margin: 2rem 1rem;

    .cancel-sub{
        margin-top: 1rem;
    }

    .card-inner {
        display: flex;
        justify-content: space-between;

        .first-div {
            display: flex;
            justify-content: start;

            .anticon {
                font-size: 40px;
            }

            .accountinfo {
                margin-left: .5rem;

                .normaltext {
                    font-weight: bold;
                    font-size: 16px;
                }

                .planname {
                    font-size: 14px;
                }
            }
        }
    }

    .second-title{
        font-size: 28px;
        font-weight: 400;
    }


}