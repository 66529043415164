.card {
	margin: 2rem !important;
	border-radius: 12px !important;
}

.wf-message {
	font-size: 1rem;
	margin-left: 12px;
}

.required {
	font-size: 12px;
	color: #cf4500;
}

.wf-title {
	font-size: 1.2rem;
	font-weight: bold;
	color: black;
}

.btn-long {
	border-radius: 12px !important;
	width: 100%;
	margin-top: 1rem;
}

.json-view {
	overflow-y: scroll;
	height: 350px !important;
	font-size: 12px;
	background-color: #f0f2f5;
}

.input-wf-name {
	border: 0px solid transparent !important;
	background-color: transparent !important;
	padding: 1rem !important;
	margin: 1rem !important;
	text-align: center;
	font-size: 2rem !important;
	font-weight: 700;
	color: black !important;
}
.common-style-wokflowlist{
	background-color: transparent !important;
	border: none !important;
}
.common-style-wokflow{
    border-radius: 5px !important;
	background-color: transparent !important;
	border: none !important;
    box-shadow:rgba(0, 0, 0, 0.2) 0px 7.5px 15px 0.1px;
}
