.initial__div {
 
}
.trial_text {
    font-weight: bolder;
    font-size: 34px;
    margin-bottom: 1rem;
}
.description-text{
    font-weight: bold;
    font-size: 15px;
}
.image-fluid{
    position: absolute;
    top: 8rem;
}

.main-div{
    position: absolute;
    top: 20rem;
    left: 12rem;

}
.card_details{
    margin-top: 1rem;
}
.button-margin{
    margin-top: 1rem;
}
 