.ant-menu-item-selected.paymate-sider-item {
  /* background-color: #cf4500 !important; */
  background-image: linear-gradient(90deg, #106acb 0%, #0097f8 100%);
  
}
.ant-menu-item-selected a{
  color: white !important;
}

.ant-menu-item-active.paymate-sider-item {
  background-image: linear-gradient(90deg, #106acb 0%, #0097f8 100%);
  /* background-color: #cf4500 !important; */
}
.ant-menu-item a:hover{
  color: white !important;
}

thead[class*='ant-table-thead'] th {
  /* background-color: #cf4500 !important; */
  background-color: #106acb !important;
  color: white !important;
}

/* progress bar */
.ant-progress-inner-custom {
  width: 90% !important;
}
.ant-progress-outer{
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.progress-percentage{
  font-size: 26px;
  letter-spacing: -1px;
  line-height: 91px;
  color: #000000;
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
}

.tooltip-color-custom{
  color: black !important;
}

.custom-header{
    background-color: #FFFDF9 !important;
    border-bottom: 1px solid rgb(215, 213, 210);
		padding: 0px !important;
		width: 100%;
}
#create-workflow-card-style{
  border-radius: 2px !important;
  padding-top: 1px;
  /* box-shadow: none; */
  border: 1px solid rgb(215, 213, 210);
  box-shadow:rgba(0, 0, 0, 0.2) 0px 7.5px 15px 0.1px;

}

.menu-item-style{
  font-size: 18px !important;
  line-height: 91px;
  color: #171717;
  font-weight: 500;
  font-family: "Poppins";
}

.work-flow-create-title {
  font-size: 50px;
  letter-spacing: -1px;
  line-height: 93px;
  color: #171717;
  font-weight: 300;
  font-family: "Poppins";
  text-align: center;
}
.work-flow-create-message-1{
  /* width: 1538px; */
  height: 25px;
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 46px;
  color: #171717;
  font-weight: 600;
  font-family: "Poppins";
  text-align: center;
}
.work-flow-create-message-2{
  /* width: 1538px; */
  height: 25px;
  font-size: 17px;
  letter-spacing: 0px;
  line-height: 56px;
  color: #171717;
  font-weight: 400;
  font-family: "Poppins";
  text-align: center;
  margin-bottom: 25px;
}
.connect-app-label{
  width: 344px;
  height: 49px;
  font-size: 21px;
  letter-spacing: 0px;
  line-height: 46px;
  color: #171717;
  font-weight: 600;
  font-family: "Poppins";
  text-align: center;
}

.select-app-field .ant-select-selector{
  filter: drop-shadow(-24.905px 21.65px 69.5px rgba(15,110,207,0.32));
  background-image: linear-gradient(90deg, #106acb 0%, #0097f8 100%) !important;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 104px;
  color: #ffffff;
  font-weight: 300;
  font-family: "Poppins";
  text-align: center;
}
.select-action-field .ant-select-selector{
  filter: drop-shadow(0 0 20px rgba(255,126,116,0.07));
  background-image: linear-gradient(90deg, #ff756a 0%, #ff8b83 100%);
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 104px;
  color: #ffffff;
  font-weight: 300;
  font-family: "Poppins";
  text-align: center;
}
/* .ant-select-selector{
  background-image: linear-gradient(90deg, #106acb 0%, #0097f8 100%) !important;
  filter: drop-shadow(-24.905px 21.65px 69.5px rgba(15,110,207,0.32));
} */

