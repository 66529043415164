.xinvform {
  .label {
    margin-left: 7px;
    color: black;
    font-size: 12px;
  }

  .hint {
    margin-left: 10px;
    margin-top: 0px;
    font-size: 10px;
  }

  .ant-row.ant-form-item {
    margin: 0 !important;
  }

  .ant-col.ant-col-22 {
    margin-top: 12px;
  }

  .fr {
    color: #ff4b02;
    font-size: 12px;
    float: right;
  }

  .rounded {
    .ant-select-selector {
      border-radius: 12px !important;
      background: #f5f5f5 !important;
    }
    .ant-select-selection-search {
      background: #f5f5f5;
    }
  }

  .title {
    font-size: 1.2rem;
    padding: 0 2rem 2rem 2rem;
  }
  .fieldset {
    padding: 2rem 1rem;
    width: 95%;
    margin: auto;
    border-radius: 12px;
  }
  legend {
    font-size: 13px !important;
  }

  .opt-div {
    .key {
      color: black !important;
      font-weight: bold !important;
      font-size: 15px;
    }
  }

  .btn-long {
    border-radius: 12px !important;
    width: 100%;
    margin-top: 1rem;
  }

  .json-view {
    overflow-y: scroll;
    height: 450px !important;
    font-size: 12px;
    background-color: #f0f2f5;
  }
}
.custom-checkbox{
  width: 91%;
}
