.ant-table-cell-fix-left, .ant-table-cell-fix-right{
        z-index: 0 !important;
}

.floating-container{
        justify-content: "center";
        align-items: "center";
        border-radius: 10;
        position: "absolute";
        bottom: 0;
        right: 0;
        background-color: "#26653A";
        padding-right: 20;
        padding-top: 10;
    }